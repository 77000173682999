// import React, { FC } from "react";
// import Image, { StaticImageData } from "next/image";

// export interface SectionProductImageProps {
//   className?: string;
//   backgroundImage: StaticImageData;
// }

// const SectionProductImage: FC<SectionProductImageProps> = ({
//   className = "",
//   backgroundImage
// }) => {
//   return (
//     <div
//       className={`nc-SectionProductImage ${className} relative overflow-hidden rounded-2xl sm:rounded-[40px]`}
//       style={{
//         width: '100%',
//         height: "50vh", // Altura basada en el viewport height para ser responsiva
//         minHeight: "400px", // Altura mínima para pantallas pequeñas
//         maxHeight: "600px", // Altura máxima para pantallas grandes
//         padding: 0 // Eliminar padding
//       }}
//     >
//       <Image
//         src={backgroundImage}
//         alt="Background"
//         layout="fill"
//         objectFit="cover"
//         className="rounded-2xl sm:rounded-[40px] p-0 m-0" // Asegurarse de que la imagen no tenga padding ni margin
//       />
//     </div>
//   );
// };

// export default SectionProductImage;

'use client';

import React from "react";
import Image from "next/image";
import { useIsMobile } from "@/hooks/useMobile";
import { useMediaQuery } from "react-responsive";


export interface SectionProductImageProps {
  className?: string;
  desktopImage: string;
  mobileImage: string;
}

const SectionProductImage: React.FC<SectionProductImageProps> = ({
  className = "",
  desktopImage,
  mobileImage
}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <div
      className={`nc-SectionProductImage ${className} relative overflow-hidden rounded-2xl sm:rounded-[40px]`}
      style={{
        width: '100%',
        height: "50vh",
        minHeight: "400px",
        maxHeight: "600px",
      }}
    >
      <Image
        src={isDesktop ? desktopImage : mobileImage}
        alt="Background"
        layout="fill"
        objectFit="cover"
        className="rounded-2xl sm:rounded-[40px]"
      />
    </div>
  );
};

export default SectionProductImage;
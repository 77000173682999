import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/DiscoverMoreSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionHero/LandingHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionProductImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionSliderBrandCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionSliderCategories/SectionSliderCategories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SectionSliderProductCard.tsx");
